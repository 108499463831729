import { ViewportRuler } from '@angular/cdk/scrolling';
import { Component, ElementRef, HostBinding, OnInit } from '@angular/core';
import {
    debugPoint,
    getMoveTransform,
    getScaleTransform,
    isTouchEvent,
    IPoint,
    prepareRect,
    IRect,
    TransformableRef,
    TransformState
} from '@drag-scale';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { concatAll, startWith, takeUntil } from 'rxjs/operators';
import { DestroyMixin } from '../../../mixins/destroy.mixin';
import { BlockBrokerService } from '../../../services';

declare type scroll = { top: number; left: number };

@Component({
    selector: 'tm-selection',
    template: '',
    styleUrls: ['./selection.component.scss']
})
export class SelectionComponent extends DestroyMixin() implements OnInit {
    constructor(
        private el: ElementRef<HTMLElement>,
        private blockBrocker: BlockBrokerService
    ) {
        super(arguments);
    }

    ngOnInit(): void {}

    position$ = new BehaviorSubject<IPoint>({ x: 0, y: 0 });
    width$ = new BehaviorSubject<number>(0);
    height$ = new BehaviorSubject<number>(0);

    source: ElementRef<HTMLElement>;
    dragWorld: TransformableRef;
    dragWorldRect: IRect;

    mouseMoveWrapper = e => this.mouseMove(e);
    mouseUpWrapper = e => this.mouseUp(e);
    mouseClickWrapper = e => this.mouseClick(e);

    private _scrollSubscription = Subscription.EMPTY;
    private stateSubscription = Subscription.EMPTY;
    private _startPosition: IPoint;
    private worldTransformState$: BehaviorSubject<TransformState>;
    private blockMap: Map<string, IRect>;
    overlapped$ = new Subject<Array<string>>();
    scale: number = 1;

    @HostBinding('style.width')
    width: string;
    @HostBinding('style.height')
    height: string;
    @HostBinding('class.isActive')
    isActive: boolean = false;
    hasMoving: boolean = false;

    private getPointerPosition(ev: MouseEvent): IPoint {
        let p = this.dragWorld.getPointerPositionOnPage(ev);
        return this.correctWorldCoordinates(p);
    }
    private correctWorldCoordinates(point: IPoint): IPoint {
        point.x -=
            this.worldTransformState$.value.position.x + this.dragWorldRect.x;
        point.y -=
            this.worldTransformState$.value.position.y + this.dragWorldRect.y;
        return point;
    }

    start(
        ev: MouseEvent,
        eventContainer: ElementRef<HTMLElement>,
        blockCache: Map<string, IRect>
    ) {
        this.source = eventContainer;
        this.blockMap = blockCache;
        this.hasMoving = false;

        this.stateSubscription = this.dragWorld.transformState$.subscribe(
            ts => (this.scale = ts.scale)
        );

        eventContainer.nativeElement.addEventListener(
            'mousemove',
            this.mouseMoveWrapper,
            true
        );
        eventContainer.nativeElement.addEventListener(
            'mouseup',
            this.mouseUpWrapper,
            { capture: true, once: true }
        );
        eventContainer.nativeElement.addEventListener(
            'click',
            this.mouseClickWrapper,
            { capture: false, once: true }
        );

        this.worldTransformState$ = this.dragWorld.transformState$;
        this._startPosition = this.getPointerPosition(ev);

        // set inital point os selection - TODO: connect with world parameters
        this.applyRootElementTransform(1, this._startPosition);
    }

    mouseMove(ev: MouseEvent) {
        ev.preventDefault();
        const pointerPosition = this.getPointerPosition(ev);

        let rectSelection = prepareRect(this._startPosition, pointerPosition);
        if (1 !== this.scale) {
            rectSelection.x /= this.scale;
            rectSelection.y /= this.scale;
            rectSelection.width /= this.scale;
            rectSelection.height /= this.scale;
        }

        this.width = `${rectSelection.width}px`;
        this.height = `${rectSelection.height}px`;
        this.applyRootElementTransform(
            1 /* this should be always 1 - we didn't want to scale selection border */,
            rectSelection
        );

        //check overlapping
        let overlapping = this.getOverlapping(
            this.el.nativeElement.getBoundingClientRect()
        );
        if (overlapping.length > 0) this.blockBrocker.selectBlock(overlapping);
        //this.overlapped$.next(overlapping);
        this.hasMoving = true;
        this.isActive = true;
        this.blockBrocker.$isSelectionActive.next(true);
    }
    mouseClick(ev: MouseEvent) {
        if (this.blockBrocker.$isSelectionActive.value && this.hasMoving) {
            // stop any other click handlers (especially on blocks listeners)
            this.blockBrocker.$isSelectionActive.next(false);
            ev.stopPropagation();
        }
    }
    mouseUp(ev: MouseEvent) {
        this.source.nativeElement.removeEventListener(
            'mousemove',
            this.mouseMoveWrapper,
            true
        );
        this._scrollSubscription.unsubscribe();
        this.stateSubscription.unsubscribe();
        this.isActive = false;
        this.width = '';
        this.height = '';
    }

    getOverlapping(selection: IRect): Array<string> {
        let overlapping = [];
        this.blockMap.forEach((r, k) => {
            let over = !(
                selection.x + selection.width < r.x ||
                selection.x > r.x + r.width ||
                selection.y > r.y + r.height ||
                selection.y + selection.height < r.y
            );
            if (over) overlapping.push(k);
        });
        return overlapping;
    }

    //TODO: Refactor - do not duplicate this ,method here
    getPointerPositionOnPage(
        event: MouseEvent | TouchEvent,
        scrollPosition: scroll
    ): IPoint {
        // `touches` will be empty for start/end events so we have to fall back to `changedTouches`.
        const point = isTouchEvent(event)
            ? event.touches[0] || event.changedTouches[0]
            : event;

        return {
            x: point.pageX - scrollPosition.left,
            y: point.pageY - scrollPosition.top
        };
    }

    private applyRootElementTransform(scale: number, pos: IPoint) {
        let transform = '';
        if (pos) {
            transform += getMoveTransform(pos);
        }
        if (scale) {
            transform += ' ' + getScaleTransform(scale);
        }
        this.el.nativeElement.style.transformOrigin = '0 0';
        this.el.nativeElement.style.transformBox = 'border-box';
        this.el.nativeElement.style.transform = transform;
    }
}
