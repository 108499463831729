import { DOCUMENT } from '@angular/common';
//import { ResizeObserver as Polyfill } from '@juggle/resize-observer';
//const ResizeObserver = window.ResizeObserver || Polyfill;
const ResizeObserver = window.ResizeObserver; // || Polyfill;

import {
    AfterViewInit,
    Component,
    ElementRef,
    HostBinding,
    Inject,
    Injector,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { BlockBrokerService, GlobalSettingsService } from '@customer/services';
import {
    activeEventListenerOptions,
    Transformable,
    TransformableRef
} from '@drag-scale';
import {
    BehaviorSubject,
    combineLatest,
    fromEvent,
    Observable,
    Subject
} from 'rxjs';
import { delay, filter, map, takeUntil } from 'rxjs/operators';
import { EditableMixin } from '@customer/mixins';
import { StoryBlock } from '@customer/domain/story-block';
import { BLOCK_KEY_BINDING } from './key.binding';
import { invertDirection, Line } from '@customer/domain';
import { BlockResizerDirective } from '@customer/directives/block-resizer.directive';

@Component({
    selector: 'tm-block',
    templateUrl: './block.component.html',
    styleUrls: ['./block.component.scss']
})
export class BlockComponent
    extends EditableMixin()
    implements AfterViewInit, OnInit, OnDestroy
{
    private static keyBindings = BLOCK_KEY_BINDING;

    @Input()
    declare textModel: string;

    @Input('block')
    blockModel: StoryBlock;

    @HostBinding('id')
    _id: string;

    @Input()
    dragContainer: TransformableRef;

    private blockTransformable: TransformableRef;
    destroy$ = new Subject<void>();
    $width: Observable<string>;

    isEditMode = false;
    isSelected = false;
    isAreaSelectionActive = false;
    hasCustomSize = false;

    @ViewChild('label', { read: ElementRef, static: true })
    label: ElementRef;
    @ViewChild('block', { read: ElementRef, static: true })
    block: ElementRef;
    @ViewChild(BlockResizerDirective)
    resizerDirective: BlockResizerDirective;

    @ViewChild('editor', { read: ViewContainerRef, static: true })
    declare editorVcr: ViewContainerRef;

    documentClickWrapper = null;
    documentKeyDownWrapper = null;
    windowResizeObserver: ResizeObserver;

    constructor(
        private blockElRef: ElementRef<HTMLElement>,
        private transformable: Transformable,
        @Inject(DOCUMENT) private _document: Document,
        public blockBroker: BlockBrokerService,
        private settingsProvider: GlobalSettingsService,
        public injector: Injector /* needs for mixins */
    ) {
        super(arguments);
        this.documentClickWrapper = ev => this.documentClickHandler(ev);
        this.documentKeyDownWrapper = ev => this.documentKeyDownHandler(ev);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.windowResizeObserver.disconnect();
        this.blockModel.$destroy.next(); // destroy model node and all related connections
        this.destroy$.next();
    }

    ngOnInit(): void {
        this.textModel = this.blockModel.text;
        this._id = this.blockModel.id;
        this.$width = this.blockModel.$width.asObservable().pipe(
            filter(x => !!x),
            map(width => `${width}px`)
        );
    }

    ngAfterViewInit(): void {
        this.blockTransformable = this.transformable.createTransformableNoScale(
            this.blockElRef,
            this.settingsProvider,
            this.dragContainer
        );
        this.blockTransformable.setPosition(this.blockModel.$position.value);

        // Hack to invert outgoing lines on moving block
        this.blockTransformable.beforeDrag
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.blockModel.$isMoving.next(true);
                // push outgoig lines to destinations
                this.blockModel.outgoingLines.forEach((line: Line) => {
                    (line.destination as StoryBlock).$movingBackLines.next([
                        new Line(
                            this.blockModel,
                            invertDirection(line.direction)
                        )
                    ]);
                });
            });
        this.blockTransformable.afterDrag
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.blockModel.$isMoving.next(false);
                // clear movings in destinations
                this.blockModel.outgoingLines.forEach((line: Line) => {
                    (line.destination as StoryBlock).$movingBackLines.next([]);
                });
            });

        this.blockTransformable.transformState$
            .pipe(takeUntil(this.destroy$))
            .subscribe(ts => {
                this.blockModel.$position.next(ts.position);
            });

        this.blockModel.$editMode
            .pipe(takeUntil(this.destroy$), delay(0))
            .subscribe(editMode => this.setEditMode(editMode));
        this.blockModel.$selected
            .pipe(takeUntil(this.destroy$), delay(0))
            .subscribe(x => (x ? this.select() : this.deselect()));

        // observe block size changes in case of autosize mode
        this.windowResizeObserver = new ResizeObserver(() => this.onResize());
        this.windowResizeObserver.observe(this.blockElRef.nativeElement);
        this.onResize();
    }

    onDbClick(ev: MouseEvent) {
        ev.stopPropagation();
        this.setEditMode(true);
    }
    onClick(ev: MouseEvent) {
        this.select();
    }
    onResize() {
        // required for inital width on first resizing for new block
        if (this.blockModel.$autoWidth.value) {
            this.resizerDirective.currentWidth =
                this.blockElRef.nativeElement.clientWidth;
            //console.log(`block ${this.blockModel.id} has autoWidth`);
            // update width for correct line positioning
        }
        //TODO: [WA-2] Fix this issue
        // requiered for lines correct positioning
        this.blockModel.$width.next(this.blockElRef.nativeElement.clientWidth);
        this.blockModel.$height.next(
            this.blockElRef.nativeElement.clientHeight
        );
    }

    select(): void {
        if (!this.isSelected) {
            // bind global click to make unselect possible
            this._document.body.addEventListener(
                'click',
                this.documentClickWrapper,
                activeEventListenerOptions
            );

            this._document.body.addEventListener(
                'keydown',
                this.documentKeyDownWrapper,
                activeEventListenerOptions
            );
        }
        this.isSelected = true;
    }
    deselect(): void {
        this.setEditMode(false);
        if (this.isSelected) {
            this._document.body.removeEventListener(
                'keydown',
                this.documentKeyDownWrapper,
                activeEventListenerOptions
            );
        }
        this.isSelected = false;
    }
    showNote() {
        this.blockBroker.showNote(this.blockModel);
    }
    showDebug() {
        this.blockBroker.showDebug(this.blockModel);
    }

    private documentClickHandler(ev: MouseEvent) {
        // skip clicks when area selection is active
        //if (this.blockBroker.$isSelectionActive.value) return;

        if (!this.isInsideClick(ev)) {
            this._document.body.removeEventListener(
                'click',
                this.documentClickWrapper,
                activeEventListenerOptions
            );
            this.deselect();
            this.setEditMode(false);
        }
    }

    private documentKeyDownHandler(ev: KeyboardEvent) {
        BlockComponent.keyBindings
            .find(x => x.predicate(this, ev))
            ?.action(this, ev);
    }

    private isInsideClick(ev: MouseEvent): boolean {
        if (this.blockElRef.nativeElement.contains(ev.target as Node)) {
            return true; // is a block
        }
        return false;
    }

    setEditMode(enable: boolean): void {
        this.isEditMode = enable;
        this.blockTransformable.disabled = enable;
        super.setEditMode(enable, model => {
            // TODO: fix this shit
            this.textModel = this.blockModel.text = model;
        });
    }
}
