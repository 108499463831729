import { KEY } from '@customer/util/key.const';

export var GLOBAL_KEY_BINDING = [
    {
        /* Ctrl+D => Open right panel with Debug info  */
        predicate: (ev: KeyboardEvent) => {
            return ev.code === KEY.B && ev.altKey;
        },
        action: (app: { showDebug() }, ev: KeyboardEvent) => {
            app.showDebug();
            ev.preventDefault();
        }
    }
];
