/* projects/customer/src/app/features/buttons/button/button.component.scss */
:host {
  padding: 5px;
  width: 50px;
  height: 50px;
  display: flex;
  vertical-align: middle;
  border-radius: 3px;
  box-sizing: border-box;
}
:host:hover {
  background-color: hsl(0, 0%, 55%);
}
img {
  width: 35px;
  text-align: left;
}
/*# sourceMappingURL=data:application/json;base64,ewogICJ2ZXJzaW9uIjogMywKICAic291cmNlcyI6IFsicHJvamVjdHMvY3VzdG9tZXIvc3JjL2FwcC9mZWF0dXJlcy9idXR0b25zL2J1dHRvbi9idXR0b24uY29tcG9uZW50LnNjc3MiXSwKICAic291cmNlc0NvbnRlbnQiOiBbIjpob3N0IHtcbiAgICBwYWRkaW5nOiA1cHg7XG4gICAgd2lkdGg6IDUwcHg7XG4gICAgaGVpZ2h0OiA1MHB4O1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgdmVydGljYWwtYWxpZ246IG1pZGRsZTtcbiAgICBib3JkZXItcmFkaXVzOiAzcHg7XG4gICAgYm94LXNpemluZzogYm9yZGVyLWJveDtcbiAgICAmOmhvdmVyIHtcbiAgICAgICAgYmFja2dyb3VuZC1jb2xvcjogaHNsKDAsIDAlLCA1NSUpO1xuICAgIH1cbn1cblxuaW1nIHtcbiAgICB3aWR0aDogMzVweDtcbiAgICB0ZXh0LWFsaWduOiBsZWZ0O1xufVxuIl0sCiAgIm1hcHBpbmdzIjogIjtBQUFBO0FBQ0ksV0FBQTtBQUNBLFNBQUE7QUFDQSxVQUFBO0FBQ0EsV0FBQTtBQUNBLGtCQUFBO0FBQ0EsaUJBQUE7QUFDQSxjQUFBOztBQUNBLEtBQUE7QUFDSSxvQkFBQSxJQUFBLENBQUEsRUFBQSxFQUFBLEVBQUE7O0FBSVI7QUFDSSxTQUFBO0FBQ0EsY0FBQTs7IiwKICAibmFtZXMiOiBbXQp9Cg== */
