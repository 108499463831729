import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { HasInjector } from './hasInjector';

type Constructor<T> = new (...args: any[]) => T;

export function DestroyMixin<T extends Constructor<any>>(
    Base: T = class {} as any
) {
    return class extends Base implements OnDestroy {
        $destroy = new Subject<void>();

        constructor(...args: any[]) {
            super(...args);
        }

        ngOnDestroy(): void {
            this.$destroy.next();
        }
    };
}
