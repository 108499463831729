import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { DestroyMixin } from '@customer/mixins/destroy.mixin';

@Component({
    selector: 'tm-save',
    templateUrl: './save.component.html',
    styleUrls: ['./save.component.scss']
})
export class SaveComponent extends DestroyMixin() {
    name: string;
    static DIALOG_ID: 'saveDialog';

    constructor(@Inject(MAT_DIALOG_DATA) public data: { mapName: string }) {
        super(arguments);
        this.dialogResult = new BehaviorSubject(data.mapName);
        this.name = data.mapName;
    }

    save() {
        this.dialogResult.next(this.name);
    }
}
