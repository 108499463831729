import { normalizePassiveListenerOptions } from '@angular/cdk/platform';
import { IPoint, IRect } from '@drag-scale';

/**
 * Time in milliseconds for which to ignore mouse events, after
 * receiving a touch event. Used to avoid doing double work for
 * touch devices where the browser fires fake mouse events, in
 * addition to touch events.
 */
export const MOUSE_EVENT_IGNORE_TIME = 800;

/** Determines whether an event is a touch event. */
export function isTouchEvent(
    event: MouseEvent | TouchEvent
): event is TouchEvent {
    // This function is called for every pixel that the user has dragged so we need it to be
    // as fast as possible. Since we only bind mouse events and touch events, we can assume
    // that if the event's name starts with `t`, it's a touch event.
    return event.type[0] === 't';
}

/** Options that can be used to bind a passive event listener. */
export const passiveEventListenerOptions = normalizePassiveListenerOptions({
    passive: true
});

/** Options that can be used to bind an active event listener. */
export const activeEventListenerOptions = normalizePassiveListenerOptions({
    passive: false
});

/**
 * Gets a scale `transform` that can be applied to an element.
 * @param x Desired scale factor of element.
 */
export function getScaleTransform(scale: number): string {
    // Round the transforms since some browsers will
    // blur the elements for sub-pixel transforms.
    return `scale(${scale})`;
}

/**
 * Gets a scale `transform` that can be applied to an element.
 * @param x Desired delta.
 */
export function getMoveTransform(point: IPoint): string {
    return `translate(${Math.round(point.x)}px, ${Math.round(point.y)}px)`;
}

export function debugPoint(msg: string, point: IPoint) {
    console.log(`${msg} x=${point.x}, y=${point.y}`);
}

// export function prepareRect(p1: Point, p2: Point): Rect {
//     let nx = 0;
//     let ny = 0;
//     return {
//         x: (nx =  p1.x < p2.x ? p1.x : p2.x), // most left point
//         y: (ny = p1.y < p2.y ? p1.y : p2.y), // most top point
//         width: (p1.x > p2.x ? p1.x : p2.x) - nx, // most right point distance
//         height: (p1.y > p2.y ? p1.y : p2.y) - ny // most bottom point distance
//     };
// }
export function prepareRect(p1: IPoint, p2: IPoint): IRect {
    return {
        x: Math.min(p1.x, p2.x), // most left point
        y: Math.min(p1.y, p2.y), // most top point
        width: Math.abs(p1.x - p2.x),
        height: Math.abs(p1.y - p2.y)
    };
}

export function scaleRectangle(
    rectangleTopLeft: IPoint,
    scalingPoint: IPoint,
    scaleFactor: number
): IPoint {
    // Calculate the vector from the scaling point to the top left corner
    let vector = {
        x: rectangleTopLeft.x - scalingPoint.x,
        y: rectangleTopLeft.y - scalingPoint.y
    };

    // Scale the vector
    let scaledVector = { x: vector.x * scaleFactor, y: vector.y * scaleFactor };

    // Calculate the new position of the top left corner
    let newTopLeft = {
        x: scalingPoint.x + scaledVector.x,
        y: scalingPoint.y + scaledVector.y
    };

    return newTopLeft;
}
