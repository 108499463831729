<div
    #block
    [class]="{ block: true, selected: isSelected, editMode: isEditMode }"
    (dblclick)="onDbClick($event)"
    (click)="onClick($event)"
    [style.width]="
        (this.blockModel.$autoWidth | async) ? null : ($width | async)
    "
>
    <div #handLeft class="resize-handle left"></div>
    <div class="middle-content">
        @if (!isEditMode) {
        <div class="ql-container no-select">
            <div #label class="ql-editor" [innerHTML]="textModel"></div>
        </div>
        }
        <template #editor></template>
    </div>
    <div
        BlockResizer
        [resizable]="this.blockModel"
        [minWidth]="50"
        #handRight
        class="resize-handle right"
    ></div>
</div>

<!--Outgoing connector lines -->
@if (!(blockModel.$isMoving | async)) { @for (line of blockModel.outgoingLines;
track line; let i = $index) {
<tm-line
    [lineDirection]="line.direction"
    arrow="forward"
    [from]="blockModel"
    [to]="line.destination"
></tm-line>
} }

<!--Moving inverted connector lines -->
@for (backLine of blockModel.$movingBackLines | async; track backLine; let i =
$index) {
<tm-line
    [lineDirection]="backLine.direction"
    arrow="back"
    [from]="blockModel"
    [to]="backLine.destination"
></tm-line>
}
