import { Point } from '@angular/cdk/drag-drop';
import {
    AfterViewInit,
    Component,
    ElementRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    BlockBrokerService,
    GlobalSettingsService,
    MapSerializerFacade
} from '@customer/services';
import {
    activeEventListenerOptions,
    IRect,
    Transformable,
    TransformableRef,
    TransformState
} from '@drag-scale';
import { BehaviorSubject, fromEvent, of } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import { MAP_FORMAT_VERSION, StoryMap } from '@customer/domain/story-map';
import { StoryBlock } from '@customer/domain/story-block';
import { StorageFacade } from '@customer/services/storage.facade';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { DestroyMixin } from '@customer/mixins/destroy.mixin';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SelectionComponent } from './selection.component';

import { SCALE_CONTAINER_KEY_BINDING } from './key.binding';
import { DOCUMENT } from '@angular/common';
import { ApiService } from '@customer/services/api.service';
import { logDebug, logError } from '@customer/util';

enum EDataSource {
    local = 'local',
    remote = 'remote'
}

@Component({
    selector: 'tm-scale-container',
    templateUrl: './scale-container.component.html',
    styleUrls: ['./scale-container.component.scss'],
    providers: []
})
export class ScaleContainerComponent
    extends DestroyMixin()
    implements OnInit, OnDestroy, AfterViewInit
{
    static keyBindings = SCALE_CONTAINER_KEY_BINDING;

    @Input()
    eventContainer: HTMLElement;

    storyMap: BehaviorSubject<StoryMap>;

    dragWorld: TransformableRef;
    transformState: TransformState;

    @ViewChild(SelectionComponent)
    selection: SelectionComponent;

    documentKeyDownWrapper = null;
    mouseDownWrapper = null;

    constructor(
        private elRef: ElementRef<HTMLElement>,
        @Inject(DOCUMENT) private _document: Document,
        private route: ActivatedRoute,
        private router: Router,
        private transformable: Transformable,
        private settingsProvider: GlobalSettingsService,
        private serializerFacade: MapSerializerFacade,
        private storageFacade: StorageFacade,
        private blockBroker: BlockBrokerService,
        private snackbar: MatSnackBar,
        private api: ApiService
    ) {
        super(arguments);
        this.documentKeyDownWrapper = ev => this.documentKeyDownHandler(ev);
        this.mouseDownWrapper = ev => this.onMouseDown(ev);
    }

    ngOnInit(): void {
        this.storyMap = this.blockBroker.$storyMap;
        this.dragWorld = this.transformable.createTransformable(
            this.elRef,
            this.settingsProvider,
            new ElementRef(this.eventContainer)
        );
        // only for drag container
        this.dragWorld.transformState$
            .pipe(takeUntil(this.$destroy))
            .subscribe(ts => {
                this.transformState = ts;
            });

        this.blockBroker.$newMap
            .pipe(takeUntil(this.$destroy))
            .subscribe(() => this.newMap());
        this.blockBroker.$saveMap
            .pipe(
                takeUntil(this.$destroy),
                tap(mapName => this.saveMap(mapName)),
                catchError((err: Error) => {
                    console.log('error ', err);
                    this.snackbar.open(`Can't save map: "${err.message}"`);
                    return of(false);
                })
            )
            .subscribe(mapName => {
                if (mapName) this.snackbar.open(`'${mapName}' saved`);
            });

        this.route.queryParamMap
            .pipe(
                takeUntil(this.$destroy),
                tap(qp => this.handleQueryParams(qp))
            )
            .subscribe(qp => logDebug(`qp changed => ${qp}`));

        // this.route.queryParamMap
        //     .pipe(takeUntil(this.$destroy))
        //     .subscribe(qp => {
        //         let mapKey, source;
        //         if (qp.has('m')) {
        //             mapKey = qp.get('m');

        //             if (qp.has('source')) {
        //                 source = qp.get('source');
        //             }

        //             try {
        //                 this.loadMap(mapKey);
        //             } catch (e) {
        //                 console.log(e);
        //                 this.snackbar.open(`Can't load map "${mapKey}"`);
        //             }
        //         } else if (this.route.snapshot.routeConfig.path === 'new') {
        //             this.newMap();
        //         } else {
        //             this.loadLastMap();
        //         }
        //     });
    }

    private handleQueryParams(queryParams: ParamMap): void {
        if (queryParams.has('m')) {
            return this.handleMapQueryParam(queryParams);
        }
        if (this.isNewMapRoute) {
            return this.newMap();
        }
        return this.loadLastMap();
    }
    private handleMapQueryParam(queryParams: ParamMap): void {
        const mapKey = queryParams.get('m');
        const source = queryParams.get('source') || null;
        let eSource: EDataSource =
            EDataSource[source as keyof typeof EDataSource];

        try {
            this.loadMap(mapKey, eSource);
        } catch (error) {
            this.logError(error, mapKey);
        }
    }
    private get isNewMapRoute(): boolean {
        return this.route.snapshot.routeConfig.path === 'new';
    }
    private logError(error, mapKey): void {
        logError(error);
        this.snackbar.open(`Can't load map "${mapKey}"`);
    }

    ngAfterViewInit(): void {
        this.selection.dragWorld = this.dragWorld;
        this.selection.dragWorldRect =
            this.eventContainer.getBoundingClientRect();

        fromEvent(this.eventContainer, 'dblclick')
            .pipe(takeUntil(this.$destroy))
            .subscribe((ev: MouseEvent) => this.onDblClick(ev));

        //selection hook
        this.eventContainer.addEventListener(
            'mousedown',
            this.mouseDownWrapper,
            {
                capture: false
            } /* act on BUBLE_STAGE */
        );

        this._document.body.addEventListener(
            'keydown',
            this.documentKeyDownWrapper,
            activeEventListenerOptions
        );
    }
    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.dragWorld.destroy();
        this.eventContainer.removeEventListener(
            'mousedown',
            this.mouseDownWrapper,
            {
                capture: false
            }
        );
    }

    private documentKeyDownHandler(ev: KeyboardEvent) {
        ScaleContainerComponent.keyBindings
            .find(x => x.predicate(this, ev))
            ?.action(this, ev);
    }

    get posX(): number {
        return this.transformState.position.x;
    }
    get posY(): number {
        return this.transformState.position.y;
    }
    get Width() {
        return this.elRef.nativeElement.clientWidth;
    }
    get Height() {
        return this.elRef.nativeElement.clientHeight;
    }

    //------- Selection ability
    onMouseDown(ev: MouseEvent) {
        if (!ev.shiftKey) return;

        //cache blocks rects
        let els = this.eventContainer.getElementsByTagName('tm-block');
        let rectMap = new Map<string, IRect>();
        for (let i = 0; i < els.length; i++) {
            rectMap.set(els[i].id, els[i].getBoundingClientRect());
        }
        // show selection area
        this.selection.start(ev, new ElementRef(this.eventContainer), rectMap);
    }
    //---------- end selection ----------

    onDblClick(ev: MouseEvent) {
        this.blockBroker.addBlock('', this.screenToWorld(ev), true);
    }

    private screenToWorld(ev: MouseEvent): Point {
        let p = this.dragWorld.getPointerPositionOnPage(ev);

        // TODO: fix this dirty hardcode
        p = {
            x: p.x - this.posX + 10,
            y: p.y - this.posY - 65
        };
        //console.log(`world: x=${this.posX} y= ${this.posY}, mouse: x=${ev.x} y=${ev.y} , position x=${p.x} y=${p.y}`)
        return p;
    }

    newMap() {
        this.blockBroker.clearAll();
        this.blockBroker.mapLoaded('💥 - New story-map -');
    }

    extractStoryMap() {
        console.log('Extract story map');

        // 1. create new Map
        // 2. Put selected blocks on it
        // 3. Put navigation block to previous map
        // 4. load new Map
        this.blockBroker.extractSelectedToNavBlock();
    }

    saveMap(name: string): void {
        const map = this.storyMap.value;
        map.name = name;

        if (map.formatVersion !== MAP_FORMAT_VERSION) {
            throw new Error(
                `Map version is too old. To preserve your data we will not override it.`
            );
        }

        const str = this.serializerFacade.Serialize(map);

        this.storageFacade.set(name, str);
        // duplicate to server
        this.api.createMap(str).subscribe(
            data => {
                this.snackbar.open(`'${name}' saved via API`);
            },
            error => {
                // Handle the error here
                console.error('There was an error!', error);
                this.snackbar.open(`Can't save '${name}' via API. ${error}`);
            }
        );
    }

    loadMap(key?: string, source?: EDataSource): void {
        if (source === EDataSource.remote) {
            return this.loadMapRemote(key);
        }
        return this.loadMapLocal(key);
    }
    private loadMapRemote(key: string): void {
        logDebug('Load map remotely by Key ', key);
        this.storageFacade
            .getRemoteMap(key)
            .pipe(takeUntil(this.$destroy))
            .subscribe(
                map => {
                    this._loadMap(map);
                    this.snackbar.open(`Map '${key}' is loaded`);
                },
                error => {
                    throw new Error(
                        `unexpected error when loading map remotely: key= "${key}", error=${error}`
                    );
                }
            );
    }
    private loadMapLocal(key: string) {
        logDebug('Load map locally by Key ', key);
        if (!this.storageFacade.has(key)) {
            throw new Error(
                `Local storage does not have a map with a key "${key}"`
            );
        }
        const strMap = JSON.parse(this.storageFacade.get(key));
        let map = this.serializerFacade.deserialize<StoryMap>(strMap, StoryMap);
        this._loadMap(map);
    }
    private _loadMap(map: StoryMap) {
        let dict = new Map<string, StoryBlock>();
        map.blocks.forEach(b => {
            dict.set(b.id, b);
        });
        map.blocks.forEach(b => {
            b.outgoingLines.forEach(line => {
                line.destination = dict.get(line.destinationId);
            });
            b.parent = dict.get(b.parentId);
        });
        this.blockBroker.load(map);
        this.blockBroker.mapLoaded(map.name);
    }

    loadLastMap(): void {
        const lastMapKey = this.storageFacade.getLastMapKey();
        if (!!lastMapKey) {
            this.router.navigate(['/map'], {
                queryParams: {
                    m: lastMapKey
                }
            });
            this.loadMap(lastMapKey);
        }
        // new map should be created
        else this.blockBroker.newMap();
    }
}
