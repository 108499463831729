/* projects/customer/src/app/features/canvas/line/line.component.scss */
:host {
  position: absolute;
  top: 50%;
  left: 50%;
}
:host svg {
  overflow: visible;
  display: block;
  width: 4px;
  height: 4px;
}
:host svg path.connector {
  stroke: #b8b5b5;
}
:host svg polyline.marker {
  fill: #b8b5b5;
}
:host svg.debug {
  stroke: red;
}
/*# sourceMappingURL=data:application/json;base64,ewogICJ2ZXJzaW9uIjogMywKICAic291cmNlcyI6IFsicHJvamVjdHMvY3VzdG9tZXIvc3JjL2FwcC9mZWF0dXJlcy9jYW52YXMvbGluZS9saW5lLmNvbXBvbmVudC5zY3NzIl0sCiAgInNvdXJjZXNDb250ZW50IjogWyI6aG9zdCB7XG4gICAgcG9zaXRpb246IGFic29sdXRlO1xuICAgIHRvcDogNTAlO1xuICAgIGxlZnQ6IDUwJTtcbiAgICAvLyB6LWluZGV4OiA5OTk7ICAvLyBkZWJ1ZyBmb3IgaGlkZGVuIHBhcnRzXG5cbiAgICAkbGluZUNvbG9yOiAjYjhiNWI1OyAvLyBkYXJrdmlvbGV0XG5cbiAgICBzdmcge1xuICAgICAgICBvdmVyZmxvdzogdmlzaWJsZTtcbiAgICAgICAgZGlzcGxheTogYmxvY2s7XG4gICAgICAgIHdpZHRoOiA0cHg7XG4gICAgICAgIGhlaWdodDogNHB4O1xuICAgICAgICAvLyAgICBiYWNrZ3JvdW5kLWNvbG9yOiBsaW1lOyAgICAgIC8vIGRlYnVnXG5cbiAgICAgICAgcGF0aC5jb25uZWN0b3Ige1xuICAgICAgICAgICAgc3Ryb2tlOiAkbGluZUNvbG9yO1xuICAgICAgICB9XG4gICAgICAgIHBvbHlsaW5lLm1hcmtlciB7XG4gICAgICAgICAgICAvL3N0cm9rZTogJGxpbmVDb2xvcjtcbiAgICAgICAgICAgIGZpbGw6ICRsaW5lQ29sb3I7XG4gICAgICAgICAgICAvL2ZpbGw6ICM5OTAwODg2NjtcbiAgICAgICAgfVxuXG4gICAgICAgICYuZGVidWcge1xuICAgICAgICAgICAgc3Ryb2tlOiByZWQ7XG4gICAgICAgIH1cbiAgICB9XG59XG4iXSwKICAibWFwcGluZ3MiOiAiO0FBQUE7QUFDSSxZQUFBO0FBQ0EsT0FBQTtBQUNBLFFBQUE7O0FBS0EsTUFBQTtBQUNJLFlBQUE7QUFDQSxXQUFBO0FBQ0EsU0FBQTtBQUNBLFVBQUE7O0FBR0EsTUFBQSxJQUFBLElBQUEsQ0FBQTtBQUNJLFVBVkk7O0FBWVIsTUFBQSxJQUFBLFFBQUEsQ0FBQTtBQUVJLFFBZEk7O0FBa0JSLE1BQUEsR0FBQSxDQUFBO0FBQ0ksVUFBQTs7IiwKICAibmFtZXMiOiBbXQp9Cg== */
