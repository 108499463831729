import { Routes } from '@angular/router';
import { CanvasComponent } from './features/canvas/canvas.component';
import { PageNotFoundComponent } from './features/page-not-found/page-not-found.component';

export const taskmapCustomerRoutes: Routes = [
    { path: 'new', component: CanvasComponent },
    { path: 'map', component: CanvasComponent },
    { path: '', redirectTo: 'map', pathMatch: 'full' },
    { path: '**', component: PageNotFoundComponent }
];
