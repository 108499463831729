import { NgModule } from '@angular/core';
import { HammerModule } from '@angular/platform-browser';
import { DragScaleService } from './lib/services/drag-scale.service';

@NgModule({
    declarations: [],
    imports: [HammerModule],
    exports: [],
    providers: [DragScaleService]
})
export class DragScaleModule {}
