import { DOCUMENT } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    HostListener,
    Inject,
    NgZone,
    OnInit,
    ViewChild
} from '@angular/core';
import { DragScaleService } from '@drag-scale';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

const MAX_ZOOM = 5;
const MIN_ZOOM = 0.1;

@Component({
    selector: 'app-canvas',
    templateUrl: './canvas.component.html',
    styleUrls: ['./canvas.component.scss']
})
export class CanvasComponent implements AfterViewInit, OnInit {
    $destroy = new Subject();
    @ViewChild('blocks', { static: true })
    blocks: ElementRef;

    zoomInfo = new BehaviorSubject<number>(100);

    constructor(
        private elRef: ElementRef<HTMLElement>,
        private ngZone: NgZone,
        @Inject(DOCUMENT) private _document: Document,
        private scaleService: DragScaleService
    ) {}

    ngOnInit(): void {
        this.scaleService.$scaleFactor
            .pipe(
                takeUntil(this.$destroy),
                tap((scale: number) => {
                    this.zoomInfo.next(Math.floor(scale));
                })
            )
            .subscribe();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (this.blocks.nativeElement) {
            this.syncCanvasSize();
        }
    }

    private syncCanvasSize() {
        // set child dimensions
        if (!this.blocks.nativeElement) {
            return;
        }
        this.blocks.nativeElement.width = Math.floor(
            this.elRef.nativeElement.clientWidth
        );
        this.blocks.nativeElement.height = Math.floor(
            this.elRef.nativeElement.clientHeight
        );
    }

    ngAfterViewInit() {
        this.syncCanvasSize();
    }
}
