<svg id="line" class="line">
    <defs>
        <marker
            id="markerArrow"
            refX="8"
            refY="4.5"
            markerUnits="userSpaceOnUse"
            orient="auto-start-reverse"
            markerWidth="8"
            markerHeight="9"
        >
            <polyline
                class="marker"
                points="0,0 8,4.5 0,9 1.5,4.5 0,0"
                stroke-width="0"
            />
        </marker>
    </defs>

    <path
        #path
        class="connector"
        [ngClass]="lineDirection"
        fill="none"
        stroke-width="1"
        [attr.marker-start]="arrow === 'back' ? 'url(#markerArrow)' : null"
        [attr.marker-end]="arrow === 'forward' ? 'url(#markerArrow)' : null"
    />

    @if (debug && S1) {
    <ng-container>
        <circle
            [attr.cx]="S1.x"
            [attr.cy]="S1.y"
            r="3"
            stroke="black"
            stroke-width="1"
            fill="red"
        />
        <circle
            [attr.cx]="S2.x"
            [attr.cy]="S2.y"
            r="3"
            stroke="black"
            stroke-width="1"
            fill="red"
        />
        <circle
            [attr.cx]="Sm.x"
            [attr.cy]="Sm.y"
            r="3"
            stroke="black"
            stroke-width="1"
            fill="red"
        />
        <circle
            [attr.cx]="S1Slope.x"
            [attr.cy]="S1Slope.y"
            r="3"
            stroke-width="0"
            fill="blue"
        />
        <circle
            [attr.cx]="S2Slope.x"
            [attr.cy]="S2Slope.y"
            r="3"
            stroke-width="0"
            fill="green"
        />
        <circle
            [attr.cx]="SmSlope.x"
            [attr.cy]="SmSlope.y"
            r="3"
            stroke-width="0"
            fill="pink"
        />
        <circle
            [attr.cx]="SmSlope2.x"
            [attr.cy]="SmSlope2.y"
            r="3"
            stroke-width="0"
            fill="#bbb"
        />
        <path [attr.d]="SlopeLine" stroke="#ccc" stroke-width="1" />
    </ng-container>
    }
</svg>
