import { environment } from '@customer/../environments/environment';

export const logDebug = function (message?: any, ...optionalParams: any[]) {
    if (environment.logLevel == 'info') return; // At 'Info' level (in production), debug messages are not logged
    console.log(message, optionalParams);
};

export const logInfo = function (message?: any, ...optionalParams: any[]) {
    if (!environment.production) {
        console.info(message, optionalParams);
    } else {
        // TBD: remote logging
        // sendToRemoteLoggingService(message);
    }
};
export const logError = function (message?: any, ...optionalParams: any[]) {
    console.error(message);
    if (!environment.production) {
        // TBD: remote logging
        // sendErrorToRemoteLoggingService(message);
    }
};
