import { KEY } from '@customer/util/key.const';
import { Direction } from '../../../domain';
import { BlockComponent } from './block.component';

export var BLOCK_KEY_BINDING = [
    {
        /* Ctrl+i => Open right panel with notes  */
        predicate: (block: BlockComponent, ev: KeyboardEvent) => {
            return (
                ev.code === KEY.I &&
                ev.ctrlKey &&
                !ev.shiftKey /* prevent affect on DevTools */
            );
        },
        action: (block: BlockComponent, ev: KeyboardEvent) => {
            block.showNote();
            ev.preventDefault();
        }
    },
    {
        /* TAB and Shift+TAB and CTRL+Alt+Arrow => create child or sibling block  */
        predicate: (block: BlockComponent, ev: KeyboardEvent) => {
            return (
                /* using TAB */
                ev.code === KEY.TAB ||
                /* or using arrows */
                (ev.ctrlKey &&
                    ev.altKey &&
                    (ev.code === KEY.RIGHT ||
                        ev.code === KEY.LEFT ||
                        ev.code === KEY.UP ||
                        ev.code === KEY.DOWN))
            );
        },
        action: (block: BlockComponent, ev: KeyboardEvent) => {
            block.deselect();

            if (ev.shiftKey && ev.code === KEY.TAB) {
                block.blockBroker.addSibling(block.blockModel);
                ev.preventDefault();
                return;
            }

            let direction: Direction = 'right';
            if (ev.ctrlKey && ev.altKey) {
                // using arrows
                switch (ev.code) {
                    case KEY.LEFT:
                        direction = 'left';
                        break;
                    case KEY.UP:
                        direction = 'up';
                        break;
                    case KEY.DOWN:
                        direction = 'down';
                        break;
                }
            }
            block.blockBroker.addChildBlock(block.blockModel, direction);
            ev.preventDefault();
        }
    },
    {
        /* Delete => Delete selected block  */
        predicate: (block: BlockComponent, ev: KeyboardEvent) => {
            return ev.code === KEY.DELETE && !block.isEditMode;
        },
        action: (block: BlockComponent, ev: KeyboardEvent) => {
            block.blockBroker.removeBlock(block.blockModel);
            ev.preventDefault();
        }
    },
    {
        /* Ctrl+Enter => Exit edit mode  */
        predicate: (block: BlockComponent, ev: KeyboardEvent) => {
            return ev.code === KEY.ESC || (ev.code === KEY.ENTER && ev.ctrlKey);
        },
        action: (block: BlockComponent, ev: KeyboardEvent) => {
            block.setEditMode(false);
            ev.preventDefault();
        }
    }
];
