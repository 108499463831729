import { Injectable } from '@angular/core';
import { CookieFacade } from './cookie.facade';
import { ApiService } from '@customer/services/api.service';
import { MapSerializerFacade } from '@customer/services//map-serializer.service';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { StoryBlock, StoryMap } from '@customer/domain';
import { logDebug } from '../util';

const LAST_MAP_KEY = '__last_map';

@Injectable({
    providedIn: 'root'
})
export class StorageFacade {
    constructor(
        private cookieService: CookieFacade,
        private serializerFacade: MapSerializerFacade,
        private api: ApiService
    ) {}

    getRemoteMaps() {
        return this.api.getMapKeys();
    }
    getRemoteMap(key: string): Observable<StoryMap> {
        return this.api.getMap(key).pipe(
            map(([map, blocks]) => {
                const story = this.serializerFacade.deserialize<StoryMap>(
                    map as string,
                    StoryMap
                );
                if (Array.isArray(blocks)) {
                    story.blocks = blocks.map(b => {
                        return this.serializerFacade.deserialize<StoryBlock>(
                            b as string,
                            StoryBlock
                        );
                    });
                }
                return story;
            })
        );
    }

    getLocalMapKeys() {
        const keys = [];
        for (let i = 0; i < localStorage.length; i++) {
            if (localStorage.key(i) === LAST_MAP_KEY) continue;
            keys.push(localStorage.key(i));
        }
        return keys;
    }

    set(key: string, value: any) {
        // this.cookieService.set(key, value);
        localStorage.setItem(key, value);
        localStorage.setItem(LAST_MAP_KEY, key);
    }

    get(key: string): any {
        //return this.cookieService.get(key);
        localStorage.setItem(LAST_MAP_KEY, key);
        return localStorage.getItem(key);
    }

    has(key: string) {
        return this.getLocalMapKeys().indexOf(key) >= 0;
        //return this.cookieService.check(key);
    }

    getLastMapKey(): string {
        return localStorage.getItem(LAST_MAP_KEY);
    }
}
