/* projects/customer/src/app/features/notes/notes.component.scss */
:host h1 {
  padding-left: 15px;
}
:host ::ng-deep .ql-editor.ql-blank::before {
  color: #bbb;
}
:host ::ng-deep .ql-toolbar.ql-snow,
:host ::ng-deep .ql-container.ql-snow {
  border-left: none !important;
  border-right: none !important;
}
/*# sourceMappingURL=data:application/json;base64,ewogICJ2ZXJzaW9uIjogMywKICAic291cmNlcyI6IFsicHJvamVjdHMvY3VzdG9tZXIvc3JjL2FwcC9mZWF0dXJlcy9ub3Rlcy9ub3Rlcy5jb21wb25lbnQuc2NzcyJdLAogICJzb3VyY2VzQ29udGVudCI6IFsiOmhvc3Qge1xuICAgIGgxIHtcbiAgICAgICAgcGFkZGluZy1sZWZ0OiAxNXB4O1xuICAgIH1cblxuICAgIDo6bmctZGVlcCB7XG4gICAgICAgIC5xbC1lZGl0b3IucWwtYmxhbms6OmJlZm9yZSB7XG4gICAgICAgICAgICBjb2xvcjogI2JiYjtcbiAgICAgICAgfVxuICAgICAgICAucWwtdG9vbGJhci5xbC1zbm93LFxuICAgICAgICAucWwtY29udGFpbmVyLnFsLXNub3cge1xuICAgICAgICAgICAgYm9yZGVyLWxlZnQ6IG5vbmUgIWltcG9ydGFudDtcbiAgICAgICAgICAgIGJvcmRlci1yaWdodDogbm9uZSAhaW1wb3J0YW50O1xuICAgICAgICB9XG4gICAgfVxufVxuIl0sCiAgIm1hcHBpbmdzIjogIjtBQUNJLE1BQUE7QUFDSSxnQkFBQTs7QUFJQSxNQUFBLFVBQUEsQ0FBQSxTQUFBLENBQUEsUUFBQTtBQUNJLFNBQUE7O0FBRUosTUFBQSxVQUFBLENBQUEsVUFBQSxDQUFBO0FBQUEsTUFBQSxVQUFBLENBQUEsWUFBQSxDQUFBO0FBRUksZUFBQTtBQUNBLGdCQUFBOzsiLAogICJuYW1lcyI6IFtdCn0K */
