import { Injectable } from '@angular/core';
import { IPoint } from '@drag-scale';
import { BehaviorSubject, Subject } from 'rxjs';
import { StoryBlock } from '../domain/story-block';
import { StoryMap } from '../domain/story-map';
import { v4 as uuidv4 } from 'uuid';
import { IBlock } from '../domain/block.interface';
import { Direction, Line } from '../domain';

export const randomPoint = () => ({
    x: Math.random() * 1400,
    y: Math.random() * 900
});

export const getChildrenRect = (childs: IBlock[]) => {
    let minX = Number.POSITIVE_INFINITY;
    let minY = Number.POSITIVE_INFINITY;
    let maxX = Number.NEGATIVE_INFINITY;
    let maxY = Number.NEGATIVE_INFINITY;
    childs.forEach(i => {
        const { x, y } = i.$position.value;
        minX = Math.min(minX, x);
        minY = Math.min(minY, y);
        maxX = Math.max(maxX, x + i.$width.value);
        maxY = Math.max(maxY, y + i.$height.value);
    });
    return { x: minX, y: minY, x1: maxX, y1: maxY };
};

@Injectable({
    providedIn: 'root'
})
export class BlockBrokerService {
    $storyMap = new BehaviorSubject(new StoryMap());
    $saveMap = new Subject<string>();
    $newMap = new Subject();
    $mapLoaded = new Subject<string>();
    $showNote = new Subject<StoryBlock>();
    $showDebug = new Subject<StoryBlock>();
    $addLine = new Subject();
    $isSelectionActive = new BehaviorSubject(false);

    blockHash = new Map<string, StoryBlock>();
    selected: StoryBlock[] = [];

    constructor() {}

    addSibling(block: StoryBlock) {
        console.log('add sibling', block.parent.id);

        if (block.parent) {
            let parentLine = block.parent.outgoingLines.find(
                l => l.destination === block
            );
            this.addChildBlock(block.parent, parentLine.direction);
        }
    }

    addLine() {
        this.$addLine.next();
    }

    saveMap(mapName: string) {
        this.$saveMap.next(mapName);
    }
    newMap() {
        this.$newMap.next();
        this.$mapLoaded.next();
    }

    mapLoaded(mapName: string) {
        this.$mapLoaded.next(mapName);
    }

    showNote(blockModel: StoryBlock) {
        this.$showNote.next(blockModel);
    }
    showDebug(blockModel: StoryBlock) {
        this.$showDebug.next(blockModel);
    }

    load(map: StoryMap) {
        this.clearAll();
        this.blockHash.clear();
        map.blocks.forEach(sb => this.blockHash.set(sb.id, sb));
        this.$storyMap.next(map);
    }

    clearAll() {
        this.$storyMap.next(new StoryMap());
        this.blockHash.clear();
    }

    selectBlock(ids: string[]) {
        ids.forEach(id => {
            if (this.blockHash.has(id)) {
                let b = this.blockHash.get(id);
                b.$selected.next(true);
                this.selected.push(b);
            }
        });
    }

    addBlock(
        text: string = '',
        position: IPoint = randomPoint(),
        editMode = false
    ): StoryBlock {
        let block = new StoryBlock(text, position, uuidv4());
        block.$selected.next(editMode);
        block.$editMode.next(editMode);
        this.$storyMap.value.blocks.push(block);
        this.blockHash.set(block.id, block);
        return block;
    }
    removeBlock(block: StoryBlock) {
        const i = this.$storyMap.value.blocks.indexOf(block);
        if (i >= 0) {
            this.$storyMap.value.blocks.splice(i, 1);
            block.$destroy.next();
            this.blockHash.delete(block.id);
        }
    }
    addChildBlock(parent: StoryBlock, direction: Direction) {
        if (!parent) throw new Error('Parent is not specified');

        let pos: IPoint;
        switch (direction) {
            case 'left': {
                pos = this.getPositionForChildLeft(parent);
                break;
            }
            case 'up': {
                pos = this.getPositionForChildTop(parent);
                break;
            }
            case 'down': {
                pos = this.getPositionForChildDown(parent);
                break;
            }
            default: {
                pos = this.getPositionForChildRight(parent);
                break;
            }
        }

        let child = new StoryBlock('', pos, uuidv4());
        parent.$selected.next(false);
        parent.$editMode.next(false);
        this.$storyMap.value.blocks.push(child);
        this.blockHash.set(child.id, child);

        parent.outgoingLines.push(new Line(child, direction)); // should draw connector line
        child.parent = parent;
        child.$selected.next(true);
        child.$editMode.next(true);

        console.log(parent);
    }

    extractSelectedToNavBlock() {
        // create nav block
        let pos = getChildrenRect(this.selected);
        pos.x = (pos.x + pos.x1) * 0.5;
        pos.y = (pos.y + pos.y1) * 0.5;
        let nav = this.addBlock('Навигация', pos);

        // link all parents of selected to created nav block
        let parentProcessedIds = new Set<string>();
        this.selected.forEach(b => {
            if (b.parent && !parentProcessedIds.has(b.parent.id)) {
                b.parent.outgoingLines.push(new Line(nav, 'down'));
                parentProcessedIds.add(b.parent.id);
            }
            this.removeBlock(b);
        });
    }

    private getPositionForChildLeft(parent: StoryBlock): IPoint {
        const pos = { ...parent.$position.value };
        pos.x -= 200;
        pos.y -= 10;

        const blocks = this.getDirectionChilds(parent.outgoingLines, 'left');

        if (0 == blocks.length) return pos;

        const rect = getChildrenRect(blocks);
        pos.x = rect.x;
        pos.y = rect.y1 + 10;
        return pos;
    }
    private getPositionForChildTop(parent: StoryBlock): IPoint {
        const pos = { ...parent.$position.value };
        pos.x -= 10;
        pos.y -= 100;

        const blocks = this.getDirectionChilds(parent.outgoingLines, 'up');

        if (0 == blocks.length) return pos;

        const rect = getChildrenRect(blocks);
        pos.x = rect.x1 + 30;
        pos.y = rect.y;

        return pos;
    }
    private getPositionForChildDown(parent: StoryBlock): IPoint {
        const pos = { ...parent.$position.value };
        pos.x -= 10;
        pos.y += 150 + parent.$height.value;

        const blocks = this.getDirectionChilds(parent.outgoingLines, 'down');

        if (0 == blocks.length) return pos;

        const rect = getChildrenRect(blocks);
        pos.x = rect.x1 + 30;
        pos.y = rect.y;

        return pos;
    }
    private getPositionForChildRight(parent: StoryBlock): IPoint {
        const pos = { ...parent.$position.value };
        pos.x += 150 + parent.$width.value;
        pos.y -= 50;

        const blocks = this.getDirectionChilds(parent.outgoingLines, 'right');
        if (0 == blocks.length) return pos;

        const rect = getChildrenRect(blocks);
        pos.x = rect.x + 0;
        pos.y = rect.y1 + 10;

        return pos;
    }

    private getDirectionChilds(lines: Line[], direction: Direction) {
        const blocks = [];
        for (let i = 0; i < lines.length; i++) {
            if (lines[i].direction === direction)
                blocks.push(lines[i].destination);
        }
        return blocks;
    }
}
