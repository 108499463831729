import { Component, OnInit } from '@angular/core';
import { BlockBrokerService } from '@customer/services';

@Component({
    selector: 'tm-button-panel',
    templateUrl: './button-panel.component.html',
    styleUrls: ['./button-panel.component.scss']
})
export class ButtonPanelComponent implements OnInit {
    constructor(private blockBroker: BlockBrokerService) {}

    ngOnInit() {}
    addBlock() {
        this.blockBroker.addBlock();
    }
    addLine() {
        this.blockBroker.addLine();
    }
}
