import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { QuillOptionsStatic } from 'quill';
import { from } from 'rxjs';
import { share, takeUntil } from 'rxjs/operators';
import { StoryBlock } from '../../domain/story-block';
import { DestroyMixin } from '../../mixins/destroy.mixin';
import { EditorInputComponent } from '../editor/editor-input.component';

export const quill_notes_config: QuillOptionsStatic = {
    /* syntax: false, */
    theme: 'snow',
    modules: {
        toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline'], // toggled buttons
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ color: [] }], // dropdown with defaults from theme
            ['clean'], // remove formatting button
            ['link', 'image', 'video'] // link and image, video
        ]
    }
};

@Component({
    selector: 'tm-notes',
    templateUrl: './notes.component.html',
    styleUrls: ['./notes.component.scss']
})
export class NotesComponent extends DestroyMixin() implements AfterViewInit {
    editor_config = quill_notes_config;
    incoming_data = undefined;

    constructor() {
        super(arguments);
    }

    ngAfterViewInit(): void {
        console.log('inited');
        this.editorInput.modelChanged
            .asObservable()
            .pipe(takeUntil(this.$destroy))
            .subscribe(m => {
                this.modelChange.emit(m);
            });
    }

    @ViewChild(EditorInputComponent, { static: false })
    editorInput: EditorInputComponent;

    @Output('model')
    modelChange = new EventEmitter<string>();

    @Input('model')
    set model(m: string) {
        this.incoming_data = m;
    }
}
