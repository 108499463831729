/* projects/customer/src/app/features/buttons/button-panel/button-panel.component.scss */
:host {
  position: absolute;
  background-color: rgba(153, 153, 153, 0.6);
  padding: 3px;
  right: 0;
  top: 0;
  width: 50px;
  display: block;
}
tm-node,
tm-text {
  display: block;
}
/*# sourceMappingURL=data:application/json;base64,ewogICJ2ZXJzaW9uIjogMywKICAic291cmNlcyI6IFsicHJvamVjdHMvY3VzdG9tZXIvc3JjL2FwcC9mZWF0dXJlcy9idXR0b25zL2J1dHRvbi1wYW5lbC9idXR0b24tcGFuZWwuY29tcG9uZW50LnNjc3MiXSwKICAic291cmNlc0NvbnRlbnQiOiBbIjpob3N0IHtcbiAgICBwb3NpdGlvbjogYWJzb2x1dGU7XG4gICAgYmFja2dyb3VuZC1jb2xvcjogIzk5OTk5OTk5O1xuICAgIHBhZGRpbmc6IDNweDtcbiAgICByaWdodDogMDtcbiAgICB0b3A6IDA7XG4gICAgd2lkdGg6IDUwcHg7XG4gICAgZGlzcGxheTogYmxvY2s7XG59XG5cbnRtLW5vZGUsXG50bS10ZXh0IHtcbiAgICBkaXNwbGF5OiBibG9jaztcbn1cbiJdLAogICJtYXBwaW5ncyI6ICI7QUFBQTtBQUNJLFlBQUE7QUFDQSxvQkFBQSxLQUFBLEdBQUEsRUFBQSxHQUFBLEVBQUEsR0FBQSxFQUFBO0FBQ0EsV0FBQTtBQUNBLFNBQUE7QUFDQSxPQUFBO0FBQ0EsU0FBQTtBQUNBLFdBQUE7O0FBR0o7QUFBQTtBQUVJLFdBQUE7OyIsCiAgIm5hbWVzIjogW10KfQo= */
