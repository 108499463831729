/* projects/customer/src/app/features/canvas/scale-container/scale-container.component.scss */
:host {
  border: none;
  display: block;
  position: absolute;
  background-color: transparent;
  width: 1500px;
  height: 1000px;
  transform-box: border-box;
  transform-origin: 0 0;
}
:host.moving {
  transition: none;
}
.lt,
.rt,
.lb,
.rb {
  position: absolute;
  text-align: center;
}
.lt {
  right: 100%;
  bottom: 100%;
}
.rt {
  left: 100%;
  bottom: 100%;
}
.lb {
  right: 100%;
  top: 100%;
}
.rb {
  left: 100%;
  top: 100%;
}
/*# sourceMappingURL=data:application/json;base64,ewogICJ2ZXJzaW9uIjogMywKICAic291cmNlcyI6IFsicHJvamVjdHMvY3VzdG9tZXIvc3JjL2FwcC9mZWF0dXJlcy9jYW52YXMvc2NhbGUtY29udGFpbmVyL3NjYWxlLWNvbnRhaW5lci5jb21wb25lbnQuc2NzcyJdLAogICJzb3VyY2VzQ29udGVudCI6IFsiOmhvc3Qge1xuICAgIGJvcmRlcjogbm9uZTtcbiAgICBkaXNwbGF5OiBibG9jaztcbiAgICBwb3NpdGlvbjogYWJzb2x1dGU7XG4gICAgYmFja2dyb3VuZC1jb2xvcjogdHJhbnNwYXJlbnQ7XG4gICAgLy9iYWNrZ3JvdW5kLWNvbG9yOiByZ2JhKDAsIDI1NSwgMCwgMC4zMDEpO1xuICAgIHdpZHRoOiAxNTAwcHg7XG4gICAgaGVpZ2h0OiAxMDAwcHg7XG4gICAgLy9iYWNrZ3JvdW5kLWltYWdlOiB1cmwoJy4uLy4uLy4uLy4uL2Fzc2V0cy9ncmlkLnN2ZycpO1xuXG4gICAgdHJhbnNmb3JtLWJveDogYm9yZGVyLWJveDtcbiAgICB0cmFuc2Zvcm0tb3JpZ2luOiAwIDA7IC8qIGltcG9ydGFudCB0aGluZyAtIHN0YXJ0IHBvaW50IG9mIHRyYW5zZm9ybWF0aW9uLiBJbnZlc3RpZ2F0ZSB0aGlzICovXG5cbiAgICAvLyB0cmFuc2l0aW9uOiB0cmFuc2Zvcm0gMC4ycztcbiAgICAvLyB0cmFuc2l0aW9uLXRpbWluZy1mdW5jdGlvbjogbGluZWFyIC8qIGVhc2Utb3V0ICovO1xuXG4gICAgJi5tb3Zpbmcge1xuICAgICAgICB0cmFuc2l0aW9uOiBub25lO1xuXG4gICAgICAgIC8vYmFja2dyb3VuZC1jb2xvcjogcGluaztcbiAgICB9XG59XG5cbi5sdCxcbi5ydCxcbi5sYixcbi5yYiB7XG4gICAgcG9zaXRpb246IGFic29sdXRlO1xuICAgIHRleHQtYWxpZ246IGNlbnRlcjtcbn1cblxuLmx0IHtcbiAgICByaWdodDogMTAwJTtcbiAgICBib3R0b206IDEwMCU7XG59XG4ucnQge1xuICAgIGxlZnQ6IDEwMCU7XG4gICAgYm90dG9tOiAxMDAlO1xufVxuLmxiIHtcbiAgICByaWdodDogMTAwJTtcbiAgICB0b3A6IDEwMCU7XG59XG4ucmIge1xuICAgIGxlZnQ6IDEwMCU7XG4gICAgdG9wOiAxMDAlO1xufVxuIl0sCiAgIm1hcHBpbmdzIjogIjtBQUFBO0FBQ0ksVUFBQTtBQUNBLFdBQUE7QUFDQSxZQUFBO0FBQ0Esb0JBQUE7QUFFQSxTQUFBO0FBQ0EsVUFBQTtBQUdBLGlCQUFBO0FBQ0Esb0JBQUEsRUFBQTs7QUFLQSxLQUFBLENBQUE7QUFDSSxjQUFBOztBQU1SLENBQUE7QUFBQSxDQUFBO0FBQUEsQ0FBQTtBQUFBLENBQUE7QUFJSSxZQUFBO0FBQ0EsY0FBQTs7QUFHSixDQVJBO0FBU0ksU0FBQTtBQUNBLFVBQUE7O0FBRUosQ0FaQTtBQWFJLFFBQUE7QUFDQSxVQUFBOztBQUVKLENBaEJBO0FBaUJJLFNBQUE7QUFDQSxPQUFBOztBQUVKLENBcEJBO0FBcUJJLFFBQUE7QUFDQSxPQUFBOzsiLAogICJuYW1lcyI6IFtdCn0K */
