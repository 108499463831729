<mat-sidenav-container class="sidenav-container" [hasBackdrop]="true">
    <mat-sidenav
        #leftSidenav
        class="sidenav"
        fixedInViewport
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="'over'"
        [opened]="false"
    >
        <div class="toggler" (click)="closeLeftSidenav()"></div>
        <mat-toolbar>{{ applicationTitle }}</mat-toolbar>
        <button mat-raised-button color="accent" (click)="saveMap()">
            Save
        </button>
        <mat-nav-list>
            <tm-nav></tm-nav>
        </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav
        #rightSidenav
        position="end"
        posi
        class="rightPanel"
        fixedInViewport
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="'over'"
        [opened]="false"
        (closedStart)="clearRightSidenav()"
    >
        <div class="fullH">
            <div class="right">
                <button
                    type="link"
                    tabindex="-1"
                    aria-label="close panel"
                    mat-icon-button
                    (click)="closeRightSidenav()"
                >
                    <mat-icon aria-label="Close panel">close</mat-icon>
                </button>
            </div>

            <!-- RIGHT Content -->
            <div class="rightContent">
                <ng-container #rightContent></ng-container>
            </div>
            <!-- end of RIGHT content -->

            <div class="actionLine">
                <button
                    tabindex="1"
                    mat-raised-button
                    color="accent"
                    (click)="closeRightSidenav()"
                >
                    Ok
                </button>
            </div>
        </div>
    </mat-sidenav>

    <mat-sidenav-content>
        <mat-toolbar color="primary">
            <button
                type="button"
                aria-label="Toggle sidenav"
                mat-icon-button
                (click)="leftSidenav.toggle()"
            >
                <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
            <!-- <span>{{ applicationTitle }}</span> -->

            <mat-icon class="logo" svgIcon="logo" aria-label="logo"
                >logo</mat-icon
            >
            <span class="spacer"></span>
            <span>{{ $currentMapName | async }}</span>
            <span class="spacer"></span>
            <!-- <button mat-raised-button color="accent" (click)="saveMap()">
                Save
            </button> -->
            <span class="spacer"></span>
            <!-- <mat-slide-toggle
                (change)="touchModeChanged($event)"
                [checked]="[isTouchPad]"
                >TouchPad</mat-slide-toggle
            > -->
        </mat-toolbar>
        <!-- Add Content Here -->
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
