/* projects/customer/src/app/features/canvas/scale-container/selection.component.scss */
:host {
  padding: 0;
  margin: 0;
  background-color: rgba(14, 37, 241, 0.1333333333);
  border: 1px solid rgba(20, 106, 146, 0.6);
  position: absolute;
  box-sizing: content-box;
  width: 0px;
  height: 0px;
  display: none;
}
:host.isActive {
  display: block;
}
/*# sourceMappingURL=data:application/json;base64,ewogICJ2ZXJzaW9uIjogMywKICAic291cmNlcyI6IFsicHJvamVjdHMvY3VzdG9tZXIvc3JjL2FwcC9mZWF0dXJlcy9jYW52YXMvc2NhbGUtY29udGFpbmVyL3NlbGVjdGlvbi5jb21wb25lbnQuc2NzcyJdLAogICJzb3VyY2VzQ29udGVudCI6IFsiOmhvc3Qge1xuICAgIHBhZGRpbmc6IDA7XG4gICAgbWFyZ2luOiAwO1xuICAgIGJhY2tncm91bmQtY29sb3I6ICMwZTI1ZjEyMjtcbiAgICBib3JkZXI6IDFweCBzb2xpZCAjMTQ2YTkyOTk7XG4gICAgcG9zaXRpb246IGFic29sdXRlO1xuICAgIGJveC1zaXppbmc6IGNvbnRlbnQtYm94O1xuICAgIC8vZGVidWdcbiAgICB3aWR0aDogMHB4O1xuICAgIGhlaWdodDogMHB4O1xuXG4gICAgZGlzcGxheTogbm9uZTtcblxuICAgICYuaXNBY3RpdmUge1xuICAgICAgICBkaXNwbGF5OiBibG9jaztcbiAgICB9XG59XG4iXSwKICAibWFwcGluZ3MiOiAiO0FBQUE7QUFDSSxXQUFBO0FBQ0EsVUFBQTtBQUNBLG9CQUFBLEtBQUEsRUFBQSxFQUFBLEVBQUEsRUFBQSxHQUFBLEVBQUE7QUFDQSxVQUFBLElBQUEsTUFBQSxLQUFBLEVBQUEsRUFBQSxHQUFBLEVBQUEsR0FBQSxFQUFBO0FBQ0EsWUFBQTtBQUNBLGNBQUE7QUFFQSxTQUFBO0FBQ0EsVUFBQTtBQUVBLFdBQUE7O0FBRUEsS0FBQSxDQUFBO0FBQ0ksV0FBQTs7IiwKICAibmFtZXMiOiBbXQp9Cg== */
